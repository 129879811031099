import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/layout";

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        nodes {
          id
          parent {
            ... on File {
              id
              name
              relativePath
              sourceInstanceName
            }
          }
          frontmatter {
            titol
            resum
            imatge {
              publicURL
            }
          }
        }
      }
      intro: allMarkdownRemark(
        filter: { frontmatter: { id: { eq: "qui-som" } } }
      ) {
        nodes {
          id
          parent {
            ... on File {
              id
              name
              relativePath
              sourceInstanceName
            }
          }
          frontmatter {
            id
          }
          html
        }
      }
    }
  `);

  const introText = data.intro.nodes[0].html;

  return (
    <Layout pageTitle="Qui sóm">
      <div
        className="blog-post-content"
        dangerouslySetInnerHTML={{ __html: introText }}
      />
    </Layout>
  );
};

export default IndexPage;
